<template>
  <div class="admin-announcements">
    <div class="page-header">
      <h1 class="page-title">
        <a-button
          type="default"
          style="display: inline; margin-right: 15px"
          @click.prevent="$router.push('/admin')"
          icon="left"
        ></a-button>

        <admin-org-indicator></admin-org-indicator>

        Admin - Announcements
      </h1>
      <div class="actions">
        <!-- Search -->
        <a-input-search
          size="large"
          placeholder="Search announcements"
          v-model="search_query"
        />
        <!-- / Search -->

        <a-button
          @click.prevent="loadAnnouncements"
          :disabled="isLoading"
          class="button-margin-left btn-rounded"
          icon="reload"
          size="large"
          type="default"
          >Refresh</a-button
        >

        <!-- <create-announcement-modal
          :visible="createAnnouncementModalVisible"
          @close-modal="closeCreateAnnouncementModal"
        ></create-announcement-modal> -->
        <!-- <a-button
          @click.prevent="openCreateAnnouncementModal"
          class="button-margin-left btn-rounded"
          icon="plus"
          size="large"
          type="primary"
          >Create</a-button
        > -->
      </div>
    </div>
    <!-- List wrapper -->
    <div class="announcements-list-wrapper">
      <!-- Loading -->
      <div class="loader" v-if="isLoading">
        <a-spin></a-spin>
      </div>
      <!-- / Loading -->

      <!-- No announcements -->
      <a-alert
        v-if="!isLoading && announcementsToShow.length == 0"
        type="info"
        message="No announcements to show"
      >
      </a-alert>
      <!-- / No announcements -->

      <!-- Loaded -->
      <div
        class="announcements-list"
        v-if="!isLoading && announcementsToShow.length"
      >
        <a-row type="flex" :gutter="20">
          <a-col
            :span="colSpan"
            v-for="announcement in announcementsToShow"
            :key="announcement.id"
          >
            <announcement-list-item
              :announcement="announcement"
              :tenant-id="tenantId"
              :org-id="selectedOrganisation.id"
              @selected="() => navigateToAnnouncement(announcement)"
            ></announcement-list-item>
          </a-col>
        </a-row>
      </div>
      <!-- / Loaded -->
    </div>
    <!-- / List wrapper -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AnnouncementListItem from "./Announcements/AnnouncementListItem.vue";
// import CreateAnnouncementModal from "../../components/Announcements/CreateAnnouncementModal.vue";
export default {
  components: {
    AnnouncementListItem,
    // CreateAnnouncementModal
  },

  data() {
    return {
      createAnnouncementModalVisible: false,
    };
  },

  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }
    this.loadAnnouncements();
  },

  watch: {
    selectedOrganisation() {
      this.loadAnnouncements();
    },
  },

  methods: {
    ...mapActions("adminAnnouncements", {
      loadAnnouncements: "loadAnnouncements",
      setSearchQuery: "setSearchQuery",
    }),

    navigateToAnnouncement(announcement) {
      this.$router.push(
        "/announcements/" +
          this.tenantId +
          "/" +
          announcement.id +
          "?admin=true"
      );
    },

    createAnnouncement() {
      this.$router.push("/call-for-help");
    },

    openCreateAnnouncementModal() {
      this.createAnnouncementModalVisible = true;
    },

    closeCreateAnnouncementModal() {
      this.createAnnouncementModalVisible = false;
    },
  },

  computed: {
    ...mapGetters("admin", {
      selectedOrganisation: "selectedOrganisation",
      tenantId: "tenantId",
    }),

    ...mapGetters("adminAnnouncements", {
      isLoading: "isLoading",
      announcements: "announcements",
      announcementsToShow: "announcementsToShow",
      searchQuery: "searchQuery",
    }),

    search_query: {
      set(val) {
        this.setSearchQuery(val);
      },
      get() {
        return this.searchQuery;
      },
    },

    colSpan() {
      // return this.windowWidth < 1200 ? 24 : 12;
      return 24;
    },
  },
};
</script>

<style scoped lang="scss">
.announcement-list-item {
  margin-bottom: 15px;
}

.button-margin-left {
  margin-left: 15px;
}
</style>