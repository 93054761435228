<template>
  <div class="announcement-list-item" @click.prevent="$emit('selected')">
    <div class="left">
        <announcement-thumbnail size="large"
        :announcement-id="announcement.id"
        :tenant-id="tenantId"
        :created-by="announcement.createdBy"
        :owner-id="announcement.ownerId"
        ></announcement-thumbnail>
    </div>
    <div class="right">
      <div class="name">
        {{ displayName }}
      </div>

      <announcement-tags :announcement="announcement"></announcement-tags>

      <div class="last-activity">
        Last activity {{ getLastActivityHuman(announcement) }}
      </div>
    </div>
  </div>
</template>

<script>
import AnnouncementThumbnail from '../../../components/Announcements/AnnouncementThumbnail.vue';
import AnnouncementTags from '../../../components/Announcements/AnnouncementTags.vue';
import Dates from "../../../mixins/Dates";
export default {
  components: { AnnouncementThumbnail, AnnouncementTags },
  props: ["announcement", "tenantId", "orgId"],
  mixins: [Dates],
  computed: {
    displayName() {
      if (
        this.announcement.displayName &&
        this.announcement.displayName.length
      ) {
        return this.announcement.displayName;
      }
      return "Unknown";
    }
  },
};
</script>

<style scoped lang="scss">
.announcement-list-item {
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid #fff;

  display: flex;

  .left {
    flex-shrink: 1;
    display: flex;
    align-items: center;

    min-width: 45px;
    text-align: center;
  }

  .right {
    padding-left: 15px;
    flex-grow: 1;
  }

  &:hover {
    color: #5155ea;
    border: 1px solid #5155ea;
  }

  .name {
    font-weight: 500;
  }
}
</style>